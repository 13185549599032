/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Head from "next/head";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React, {
	useCallback, useEffect, useState, useMemo,
} from "react";
import { useRouter } from "next/router";
import {
	IconSearch, IconBell, IconShoppingCart,
} from "@tabler/icons";
import axios from "axios";
import Cookies from "js-cookie";
import {
	IconMyOrder,
} from "Components/icons";
import { useDispatch, useSelector } from "react-redux";
import Env from "Consts/env";
import {
	// IdrConvert,
	ScrollToUp,
	HandleCreateQuote,
	// openZendeskWidget,
	// closeZendeskWidget,
} from "Helpers/utils";
import { setDataLayerNew } from "Helpers/dataLayer";
import { loginOrSignUp } from "Helpers/is498";
import NavbarHeader from "@fdn/navbar_header";
import SearchWrapper from "Components/search/search-wrapper";
import {
	DATA_LAYER_SEARCH_CLICK,
	DATA_LAYER_SEARCH_DEFAULT_CLICK,
	DATA_LAYER_SEARCH_QUICK_CLICK,
	DATA_LAYER_SEARCH_TYPING,
	VIEW_SEARCH_RESULT_PAGE,
} from "Consts/dataLayer";
import Vars from "../consts/vars";
import { loadCartBadgeCount } from "@/Store/cart_badge_count_store/lib/actions";
import { loadMergeCart } from "@/Store/merge_cart_store/lib/actions";

const baseAppName = "FD Studio";
let appName = "";
const metaDefault = {
	title: "Temukan Diskon Skincare dan Kosmetik Terlengkap",
	description: "Temukan Produk terkini dan terlengkap seputar dunia kecantikan mulai dari skin care, makeup, review produk dan brand kecantikan lokal maupun internasional.",
	image: "https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/error-image/image-error.png",
};

const Popup = dynamic(
	() => import("Components/pdp-popup"),
	{ ssr: false },
);

const Footer = dynamic(
	() => import("./footer"),
	{ ssr: false },
);

const Sidebar = dynamic(
	() => import("Components/sidebar"),
	{ ssr: false },
);

const NavbarSecondary = dynamic(
	() => import("Components/navbar-secondary"),
	{ ssr: false },
);

const CategoryDesktop = dynamic(
	() => import("Components/category-desktop"),
	{ ssr: false },
);

// const ZendeskLauncher = dynamic(
// 	() => import("Components/zendesk-launcher"),
// 	{ ssr: false },
// );

export default function Layout({
	children,
	titleNavbar,
	typeNavbarSecondary,
	ismobile,
	isShowSecondaryNav,
	self,
	isFooter,
	isHideAction,
	categoryDesktop,
	getQuoteIdErrMessage,
	pageTitle,
	metaDescription,
	metaKeywords,
	metaTitle,
	metaImage,
	metaUrlPath,
	device,
	hideMetaDescription,
}) {

	let newCategoryDesktop = [];
	newCategoryDesktop = self?.data?.id
		? categoryDesktop?.filter((item) => !["for-you"].includes(item.slug) && item)
		: categoryDesktop;

	const isMetaTitleWithBaseName = metaTitle?.includes("|");
	if (typeof metaTitle !== "undefined") {

		const cleanMetaTitle = metaTitle?.split("|")?.[0];
		appName = `${pageTitle || cleanMetaTitle} | ${baseAppName}`;

	}

	const isMobile = ismobile || false;

	useCallback(() => {

		ScrollToUp();

	}, []);

	const { pathname, query } = useRouter();

	const dispatch = useDispatch();
	const getCartBadgeCount = useSelector((state) => state.storeCartBadgeCount.data);

	const [searchText, setSearchText] = useState("");
	const [isShowSearch, setShowSearch] = useState(false);
	const [isShowSearchInput, setShowSearchInput] = useState(false);
	const [searchLoading, setSearchLoading] = useState(false);
	const [isShowSidebar, setShowSidebar] = useState(false);
	const [isNavbarSecondary, setNavbarSecondary] = useState(false);
	const [isNavbarHeader, setNavbarHeader] = useState(true);
	const [popupShow, setPopupShow] = useState({
		show: false,
		message: "",
		color: "#323942",
		closable: true,
	});
	const getMergeCart = useSelector((state) => (state.storeMergeCart?.data));
	// const [isZendeskLoad, setIsZendeskLoad] = useState(false);
	const [isSelfComplete, setIsSelfComplete] = useState(false);

	const [IsMyOrderPopup, SetIsMyOrderPopup] = useState(false);

	//* for search navbar
	const [isDefaultResult, setIsDefaultResult] = useState(true);
	const [searchDefaultData, setSearchDefaultData] = useState(null);
	const [searchQuickData, setSearchQuickData] = useState(null);
	const [searchCampaignName, setSearchCampaignName] = useState("");

	const [isVerified, setIsVerified] = useState(null);

	const fetchCompleteProfile = async () => {

		const token = Cookies.get("token");

		// Exit early if there's no token
		if (!token) return;

		const API_URL = "/api/fds-profile/complete";
		const HEADERS = {
			...Env.HEADERS,
			key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
			authorization: token,
		};

		try {

			// Fetch the complete profile
			const response = await fetch(API_URL, { headers: HEADERS });

			if (!response.ok) {

				throw new Error(`Failed to fetch: ${response.statusText}`);

			}

			const { data } = await response.json();

			// Check if required statuses are verified
			const requiredStatuses = ["complete_verify_email", "complete_verify_phone"];

			const isAllVerified = requiredStatuses.every((status) => data?.account_status?.some(
				(item) => item?.description === status && item?.status === true,
			));

			if (isAllVerified) {

				setIsVerified(true);

			} else {

				setIsVerified(false);

			}

		} catch (error) {

			throw new Error(error.message || "An unknown error occurred.");

		}

	};

	const fetchCampaignSearch = async () => {

		try {

			const dataSearchCampaign = await fetch(
				"/api/fds-search/campaign",
				{
					headers: {
						...Env.HEADERS,
						key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
					},
				},
			);

			const resSearchCampaign = await dataSearchCampaign.json();
			const CampaignName = resSearchCampaign.data?.campaign_name;
			setSearchCampaignName(CampaignName);

			return resSearchCampaign;

		} catch (error) {

			setSearchLoading(false);

			setPopupShow({
				show: true,
				message: "Something went wrong. Please try again later.",
				color: "#323942",
				closable: true,
			});

			return { data: null, meta: null, error: `Error: ${error}` };

		}

	};

	const fetchDefaultSearch = async () => {

		setIsDefaultResult(true);

		try {

			const dataSearchDefault = await fetch(
				"/api/fds-search/default",
				{
					headers: {
						...Env.HEADERS,
						key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
						authorization: Cookies.get("token") || "",
					},
				},
			);

			const resSearchDefault = await dataSearchDefault.json();
			setSearchLoading(false);

			return resSearchDefault;

		} catch (error) {

			setSearchLoading(false);

			setPopupShow({
				show: true,
				message: "Something went wrong. Please try again later.",
				color: "#323942",
				closable: true,
			});

			return { data: null, meta: null, error: `Error: ${error}` };

		}

	};

	const fetchQuickSearch = async (keyword) => {

		try {

			const dataSearchQuick = await fetch(
				"/api/fds-search/quick",
				{
					headers: {
						...Env.HEADERS,
						key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
						authorization: Cookies.get("token") || "",
						keyword: encodeURIComponent(keyword),
					},
				},
			);

			const resSearchQuick = await dataSearchQuick.json();

			setSearchLoading(false);

			return resSearchQuick;

		} catch (error) {

			setSearchLoading(false);

			setPopupShow({
				show: true,
				message: "Something went wrong. Please try again later.",
				color: "#323942",
				closable: true,
			});

			return { data: null, meta: null, error: `Error: ${error}` };

		}

	};

	const deleteOneSearchHistory = async (keywords) => {

		const arr = searchDefaultData?.data?.history?.filter((data) => data !== keywords[0]);

		setSearchDefaultData((prevData) => ({
			...prevData,
			data: {
				...prevData.data,
				history: arr,
			},
		}));

		try {

			const deleteSearchHistory = await fetch(
				"/api/fds-search/deleteHistory",
				{
					headers: {
						...Env.HEADERS,
						key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
						authorization: Cookies.get("token"),
					},
					method: "PATCH",
					body: JSON.stringify({ data: { keywords } }),
				},
			);

			const resDeleteHistory = await deleteSearchHistory.json();

			return resDeleteHistory;

		} catch (error) {

			setPopupShow({
				show: true,
				message: "Something went wrong. Please try again later.",
				color: "#323942",
				closable: true,
			});

			return { data: null, meta: null, error: `Error: ${error}` };

		}

	};

	const clearSearchHistory = async () => {

		try {

			const clearAllSearchHistory = await fetch(
				"/api/fds-search/clearHistory",
				{
					headers: {
						...Env.HEADERS,
						key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
						authorization: Cookies.get("token"),
					},
					method: "PATCH",
				},
			);

			const resClearHistory = await clearAllSearchHistory.json();

			if (resClearHistory.meta.code >= 200) {

				// * fetch default search
				const defaultSearchData = await fetchDefaultSearch();
				setSearchDefaultData(defaultSearchData);

			}

			return resClearHistory;

		} catch (error) {

			setPopupShow({
				show: true,
				message: "Something went wrong. Please try again later.",
				color: "#323942",
				closable: true,
			});

			return { data: null, meta: null, error: `Error: ${error}` };

		}

	};

	const submitSearchHistory = async (keyword) => {

		let resSubmitHistory;

		if (Cookies.get("token")) {

			try {

				const submitHistory = await fetch(
					"/api/fds-search/submitHistory",
					{
						headers: {
							...Env.HEADERS,
							key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
							authorization: Cookies.get("token"),
						},
						method: "POST",
						body: JSON.stringify({ keyword }),
					},
				);

				resSubmitHistory = await submitHistory.json();

			} catch (error) {

				setPopupShow({
					show: true,
					message: "Something went wrong. Please try again later.",
					color: "#323942",
					closable: true,
				});

			}

		}

		return resSubmitHistory;

	};

	const SearchOnClick = async () => {

		// * set datalayer when click search
		setDataLayerNew()?.search({
			event: DATA_LAYER_SEARCH_CLICK,
			username: self.data?.username || "guest",
			is_login: !!self.data?.username,
			device: isMobile ? "mobile" : "desktop",
			referral: `fromglobalheaderfdstudio${pathname.split("/")[1].replace(/[-]/g, "")}`,
		});

		if (isMobile) {

			setShowSearchInput(true);

		}

		setShowSearch(true);

		if (!searchDefaultData) {

			setSearchLoading(true);

			// * here fetching default search
			const defaultSearchData = await fetchDefaultSearch();
			setSearchDefaultData(defaultSearchData);

		}

	};

	const updateSearchText = async (text) => {

		if (text.length > 2) {

			setIsDefaultResult(false);
			setShowSearch(true);

			// * set datalayer when typing search
			setDataLayerNew()?.search({
				event: DATA_LAYER_SEARCH_TYPING,
				username: self.data?.username || "guest",
				is_login: !!self.data?.username,
				device: isMobile ? "mobile" : "desktop",
				keyword: text,
				referral: `fromglobalheaderfdstudio${pathname.split("/")[1].replace(/[-]/g, "")}`,
			});

			// * Fetch QuickSearch here using text for keyword
			const resSearchQuick = await fetchQuickSearch(text);
			setSearchQuickData(resSearchQuick);

		} else {

			setShowSearch(false);
			setIsDefaultResult(true);

		}

	};

	const handleSetDataLayerSearchResult = (section) => {

		//* Data layer from enter or click search and view all section
		setDataLayerNew()?.search({
			event: section ? DATA_LAYER_SEARCH_QUICK_CLICK : VIEW_SEARCH_RESULT_PAGE,
			username: self.data?.username || "guest",
			is_login: !!self.data?.username,
			device: isMobile ? "mobile" : "desktop",
			keyword: searchText,
			viewAllSection: section,
			referral: `fromglobalheaderfdstudio${pathname.split("/")[1].replace(/[-]/g, "")}`,
		});

	};

	const submitSearch = async (e) => {

		e.preventDefault();
		// * Submit history search
		await submitSearchHistory(searchText);
		// * set data layer when click search & click view all
		handleSetDataLayerSearchResult();

		// * keyword search can't reset if using route.push
		window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/search?query=${encodeURIComponent(searchText)}`;

	};

	const submitTrendingSearch = async (e, value) => {

		e.preventDefault();
		// * Submit history search
		await submitSearchHistory(value);

		// * keyword search can't reset if using route.push
		window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/search?query=${encodeURIComponent(value)}&ref=default`;

	};

	const onClickCustomIcon = (val) => {

		if (val?.target?.className?.baseVal?.includes("cart")) {

			window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/cart`;

		} else if (val?.target?.className?.baseVal?.includes("bell")) {

			window.location.href = `${process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}/notification?tab=reviews`;

		}

	};

	const showSearch = () => {

		setShowSearchInput(true);

	};

	const HandleClickBack = () => {

		window.history.go(-1); return false;

	};

	const HandleClickSearch = () => {

		ScrollToUp();
		SearchOnClick();

	};

	const HandleClickCart = () => {

		window.location.href = "/cart";

	};

	const handleOnChangeSearch = (e) => {

		setSearchText(e.target.value);

		if (e.target?.value?.length > 2) {

			setIsDefaultResult(false);

		} else {

			setIsDefaultResult(true);
			setSearchLoading(false);

		}

	};

	const handleSetDataLayer = (data, section, isQuick) => {

		// * set datalayer when click section search
		setDataLayerNew()?.search({
			event: isQuick ? DATA_LAYER_SEARCH_QUICK_CLICK : DATA_LAYER_SEARCH_DEFAULT_CLICK,
			username: self.data?.username || "guest",
			is_login: !!self.data?.username,
			device: isMobile ? "mobile" : "desktop",
			keyword: searchText,
			data,
			section,
			referral: `fromglobalheaderfdstudio${pathname.split("/")[1].replace(/[-]/g, "")}`,
		});

	};

	useEffect(() => {

		if (isShowSearch) {

			document.body.style.overflow = "hidden";

		} else {

			document.body.style.overflow = "unset";
			document.body.style.overflowX = "hidden";

		}

	}, [isShowSearch]);

	useEffect(() => {

		const onScroll = () => {

			if (window && isShowSecondaryNav) {

				const windowHeight = window.scrollY;
				const regexList = [/^\/$/,
					/story/,
					/faq/,
					/privacy-policy/,
					/terms-and-conditions/,
					/detail/,
					/brand/,
					/category/,
					/my-order/,
					/search/,
					/flash-sale/,
					/promotions/,
				];
				const isMatch = regexList.some((rx) => rx.test(pathname.toString()));

				if (
					isMatch
					&& isMobile
				) {

					if (windowHeight > 62) {

						setNavbarSecondary(true);
						document.getElementsByClassName("navbar-header")[0]?.style.setProperty("display", "none");

					} else {

						setNavbarSecondary(false);
						document.getElementsByClassName("navbar-header")[0]?.style.setProperty("display", "block");

					}

				}

			}

		};

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);

	}, [isMobile]);

	useEffect(() => {

		const onScroll = () => {

			if (window) {

				const regexList = [/^\/$/,
					/story/,
					/faq/,
					/privacy-policy/,
					/terms-and-conditions/,
					/detail/,
					/brand/,
					/category/,
					/my-order/,
					/search/,
					/flash-sale/,
					/promotions/,
				];
				const isMatch = regexList.some((rx) => rx.test(pathname.toString()));
				if (device && (device !== 1 || device !== 2)) {

					setNavbarSecondary(false);
					setNavbarHeader(false);

				} else if (!isMatch && isMobile) {

					setNavbarSecondary(true);
					setNavbarHeader(false);

				}

			}

		};
		onScroll();

	}, []);

	useEffect(() => {

		if (getQuoteIdErrMessage) {

			setPopupShow({
				show: true,
				message: "Mohon untuk direfresh kembali",
				color: "#323942",
			});

		}

	}, [getQuoteIdErrMessage]);

	useEffect(() => {

		let interval;

		if ((searchText.length > 2 && !isMobile)
			|| (searchText.length > 2 && isMobile)) {

			setSearchLoading(true);

			interval = setTimeout(() => {

				updateSearchText(searchText);

			}, 500);

		}

		return () => {

			clearTimeout(interval);

		};

	}, [searchText]);

	useEffect(() => {

		const token = Cookies.get("token") || null;
		const quoteId = Cookies.get("quoteId") || null;
		const userId = Cookies.get("user_id") || null;

		const createQuote = async () => {

			const res = await HandleCreateQuote({}, dispatch);
			return res;

		};

		if (token && !userId && !quoteId) {

			console.log("QUOTE AND MERGE");
			createQuote().then((res) => {

				if (res.data.meta.code >= 200 && res.data.meta.code < 300 && res.data) {

					dispatch(loadMergeCart());

				}

			});

		} else if (token && quoteId && !userId) {

			console.log("MERGE");
			dispatch(loadMergeCart());

		} else if (!token && !quoteId && !userId) {

			createQuote();

		}

	}, []);

	useEffect(() => {

		const isUserId = getMergeCart?.data?.user_id || null;

		if (isUserId) {

			const domain = (new URL(`https://${process.env.NEXT_PUBLIC_HOST_ROOT_DOMAIN}`)).hostname.replace("www.", "");
			Cookies.remove("quoteId", { path: "", domain });
			Cookies.set("user_id", getMergeCart?.data?.user_id, { path: "", domain });

		}

		const mergeCartErrorMessage = getMergeCart?.meta?.msg;

		if (getMergeCart?.meta?.code === 400 && mergeCartErrorMessage) {

			setPopupShow({
				show: true,
				message: `${mergeCartErrorMessage}`,
				color: "#FF4D4F !important",
				closable: true,
				redirect: false,
			});

		}

	}, [getMergeCart]);

	useEffect(() => {

		setShowSearch(false);
		setShowSearchInput(false);

	}, [query]);

	axios.defaults.headers = {
		...Env.HEADERS,
	};

	const onLogout = () => {

		const domain = (new URL(process.env.NEXT_PUBLIC_ROOT_HOME)).hostname.replace("www.", "");
		Cookies.remove("token", { path: "", domain });
		Cookies.remove("quoteId", { path: "", domain });
		Cookies.remove("tokenMagento", { path: "", domain });
		Cookies.remove("user_id", { path: "", domain });
		window.location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/logout`;

	};

	/* START LOAD CART TOTAL */
	useEffect(() => {

		dispatch(loadCartBadgeCount());

		fetchCampaignSearch();

		fetchCompleteProfile();

	}, []);
	/* END LOAD CART TOTAL */

	const isZendeskShow = useMemo(() => {

		const regexList = [/^\/$/,
			/(\/brands\/[^/?]+)($|\/|\?)/,
			/detail/,
			/category/,
			/search/,
		];
		return regexList.some((rx) => rx.test(pathname.toString()));

	}, [pathname]);

	const mainContentPadding = () => {

		const isSearchPage = pathname.includes("search");

		let padding;
		if (!isMobile) {

			padding = isSearchPage ? "56px 0 80px 0" : "56px 0";

		} else if (!isNavbarHeader) {

			padding = isSearchPage ? "63px 0 80px 0" : "63px 0 0 0";

		} else {

			padding = isSearchPage ? "0 0 80px 0" : "0";

		}

		return padding;

	};

	useEffect(!isZendeskShow
		? () => { }
		: () => {

			const script = document.createElement("script");
			script.src = "https://static.zdassets.com/ekr/snippet.js?key=944e5ef8-049f-4b62-aa8d-fc15e679561f";
			script.id = "ze-snippet";
			script.setAttribute("type", "text/javascript");
			script.async = true;
			document.head.appendChild(script);
			script.onload = () => {

				const scriptTag = document.createElement("script");
				scriptTag.type = "text/javascript";
				scriptTag.text = `
					zE("webWidget", "hide")
					zE('webWidget:on', 'close', function() {
						zE('webWidget', 'hide');
						document.querySelector('.zendeskChatWidget').style.opacity = 1;
					})
					`;
				document.body.appendChild(scriptTag);
				// setIsZendeskLoad(true);

			};

			return () => {

				// setIsZendeskLoad(false);
				document.head.removeChild(script);

			};

		}, []);

	const remapUrl = (url) => {

		const encoded = encodeURIComponent(url);
		const newUrl = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/_next/image?url=${encoded}&w=1200&q=80`;

		return newUrl;

	};

	const IconListNavbar = [
		isMobile && (
			<span
				key="1"
				className="notification"
				style={{ cursor: "pointer" }}
				onClick={SearchOnClick}
			>
				<IconSearch stroke="1" />
			</span>
		),
		<span key="2" className="cart" data-cart={Number(getCartBadgeCount) || 0} style={{ cursor: "pointer", marginLeft: "10px" }}>
			<IconShoppingCart stroke="1" />
			<style>
				{`
					.cart::after {
						visibility: ${(Number(getCartBadgeCount) || 0) ? "visible" : "hidden"};
						content: attr(data-cart);
						font-size: 10px;
						background: #DB264D;
						color: #fff;
						vertical-align: top;
						position: absolute;
						top: ${isMobile ? "12px" : "20px"};
						margin-left: -7px;
						border-radius: 50%;
						width: 14px;
						height: 14px;
						text-align: center;
					}
				`}
			</style>
		</span>,
		<span key="3" className="notification" style={{ cursor: "pointer", marginLeft: "10px" }}>
			<IconBell stroke="1" />
		</span>,
	];

	// ? this useEffect just temporary, until fetch self is updated
	// ? and just for handling loading state
	useEffect(() => {

		setIsSelfComplete(false);

		const timeOutSelf = setTimeout(() => {

			if (self?.status === Vars.FETCH_RESULT) {

				setIsSelfComplete(true);

			}

			setIsSelfComplete(true);

		}, 3000);

		return () => clearTimeout(timeOutSelf);

	}, [self]);

	return (
		<>
			<Head>
				<title>{appName}</title>
				<link rel="icon" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/beauty_studio/favicon.ico" />
				<meta charSet="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
				<link rel="apple-touch-icon-precomposed" sizes="144x144" href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_144.png`} />
				<link rel="apple-touch-icon-precomposed" sizes="114x114" href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_144.png`} />
				<link rel="apple-touch-icon-precomposed" sizes="72x72" href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_72.png`} />
				<link rel="apple-touch-icon-precomposed" sizes="57x57" href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/images/fdn_logo_57.png`} />

				{/* META AUTHOR */}
				<meta name="author" content="Female Daily Network" />
				<meta
					name="title"
					content={isMetaTitleWithBaseName ? metaTitle : `${metaTitle || metaDefault.title} | ${baseAppName}`}
				/>

				<meta
					name="keywords"
					content={metaKeywords}
				/>
				<meta property="og:locale" content="en_US" />
				<meta property="og:locale" content="id_ID" />
				<meta property="og:type" content="website" />
				<meta name="twitter:card" content="summary_large_image" />

				<meta property="og:title" content={isMetaTitleWithBaseName ? metaTitle : `${metaTitle || metaDefault.title} | ${baseAppName}`} />
				<meta name="twitter:title" content={isMetaTitleWithBaseName ? metaTitle : `${metaTitle || metaDefault.title} | ${baseAppName}`} />

				<meta
					name="description"
					content={hideMetaDescription ? "" : metaDescription || metaDefault.description}
				/>
				<meta property="og:description" content={hideMetaDescription ? "" : metaDescription || metaDefault.description} />
				<meta name="twitter:description" content={hideMetaDescription ? "" : metaDescription || metaDefault.description} />

				<meta property="og:keywords" content={metaKeywords} />
				<meta property="og:url" content={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}${metaUrlPath || ""}`} />
				<meta property="og:site_name" content="Female Daily Studio" />

				<meta property="og:image" content={metaImage ? remapUrl(metaImage) : metaDefault.image} />
				<meta property="og:image:url" content={metaImage ? remapUrl(metaImage) : metaDefault.image} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />

				<meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FB_APP_ID} />

				<meta name="twitter:image" content={metaImage ? remapUrl(metaImage) : metaDefault.image} />
				<meta name="twitter:image:src" content={metaImage ? remapUrl(metaImage) : metaDefault.image} />

				<link
					rel="stylesheet"
					href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/retinol-design/css/studio/main.min.css"
				/>
				<link rel="stylesheet" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/fdn-studio-new/static/styles/font_google.css" />
				<link rel="stylesheet" type="text/css" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/fdn-studio-new/static/styles/icomoon/styles.css" />
				<link rel="stylesheet" type="text/css" href="/styles/style.css" />
				<link rel="stylesheet" type="text/css" href="/styles/typography.css" />
				<link rel="stylesheet" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/fonts/fdnfonts.css" />
				<link rel="stylesheet" type="text/css" href="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/font/fontastic/styles.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
				<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
				<link rel="stylesheet" href="https://unpkg.com/@tabler/icons@latest/iconfont/tabler-icons.min.css" />
				<script src="https://unpkg.com/@tabler/icons@latest/icons-react/dist/index.umd.min.js" />

			</Head>

			{/* {isZendeskShow && isZendeskLoad && (
				<ZendeskLauncher
					isMobile={isMobile}
					onShow={openZendeskWidget}
					onClose={closeZendeskWidget}
				/>
			)} */}

			{
				isNavbarHeader
				&& (
					<NavbarHeader
						isNewIcon
						logo_img="https://s3.ap-southeast-1.amazonaws.com/assets.femaledaily.com/beauty_studio/logo-fd-studio.png"
						is_mobile={isMobile}
						type="monochrome"
						self={self && self.data}
						is_fetch_complete={
							isSelfComplete
						}
						nav_position="fixed"
						menu={Vars.top_menu}
						main_link={process.env.NEXT_PUBLIC_ROOT_DOMAIN}
						home_link="/"
						reviews_link={process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}
						talk_link={process.env.NEXT_PUBLIC_TALK_DOMAIN}
						sso_link={process.env.NEXT_PUBLIC_SSO_DOMAIN}
						is_user_verified={isVerified}
						profile_link={self && `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/${self.data?.username}`}
						sidebar_config={Vars.config_sidebar}
						logo_id="id_logo_editorial"
						search_input_id="id_search_input_field_editorial"
						button_login_id="id_loginsignup_editorial"
						button_cart_id="id_carteditorial"
						button_notif_id="id_notifeditorial"
						submit_search={(e) => submitSearch(e)}
						search_text={searchText}
						update_search_text={handleOnChangeSearch}
						toggle_sidebar={() => setShowSidebar(true)}
						close_search_suggest={() => {

							setSearchText("");
							setShowSearch(false);

						}}
						is_show_search_suggest={isShowSearch}
						is_show_search={isShowSearchInput}
						show_search={showSearch}
						hide_search={() => {

							setIsDefaultResult(true);
							setShowSearch(false);
							setShowSearchInput(false);
							setSearchText("");

						}}
						customIcons={
							IconListNavbar
						}
						handleClickCustomIcons={(val) => onClickCustomIcon(val)}
						onClickLoginSignup={() => {

							loginOrSignUp();

						}}
						loggedInMenu={
							(
								<>
									<a
										id="id_myorderdropdown"
										href={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/my-order`}
										className="option-menu"
										onMouseEnter={() => SetIsMyOrderPopup(!IsMyOrderPopup)}
										onMouseLeave={() => SetIsMyOrderPopup(!IsMyOrderPopup)}
									>
										<IconMyOrder active={IsMyOrderPopup} padding="0 8px 0 0" />
										<span>My Order</span>
									</a>
									<a
										id="id_profiledropdown"
										href={self && `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/${self.data?.username}`}
										className="option-menu"
									>
										<i className="fdicon-bs-profile" />
										<span>Profile</span>
									</a>
									<a
										id="id_settingdropdown"
										href={self && `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/my/account`}
										className="option-menu"
									>
										<i className="fdicon-bs-setting" />
										<span>Settings</span>
									</a>
									<div
										id="id_logoutdropdown"
										onClick={() => onLogout()}
										className="option-menu"
									>
										<i className="fdicon-bs-logout" />
										<span>Logout</span>
									</div>
								</>
							)
						}
						// searchContent={
						// 	SearchContent
						// }
						search_placeholder={
							searchCampaignName || "Search Brand or Product"
						}
						search_on_click={SearchOnClick}
						close_search_wrapper={() => {

							setShowSearchInput(false);
							setShowSearch(false);

						}}
					>
						{isShowSearch && (
							<NavbarHeader.SearchWrapper>
								<SearchWrapper
									isMobile={isMobile}
									isLoading={searchLoading}
									isDefault={isDefaultResult}

									searchDefault={{
										data: searchDefaultData?.data || null,
										status: searchDefaultData?.meta?.msg,
										code: searchDefaultData?.meta?.code,
									}}

									searchQuick={{
										data: searchQuickData?.data || null,
										status: searchDefaultData?.meta?.msg,
										code: searchDefaultData?.meta?.code,
									}}
									handleNoResult={
										searchQuickData?.data?.results?.brands < 1
										&& searchQuickData?.data?.results?.products < 1
									}
									deleteHistorySearch={deleteOneSearchHistory}
									clearHistorySearch={clearSearchHistory}
									submitSearch={submitTrendingSearch}
									searchText={searchText}
									onClickDataLayer={handleSetDataLayer}
									onClickDataLayerSubmit={handleSetDataLayerSearchResult}
									submitSearchHistory={submitSearchHistory}
								/>
							</NavbarHeader.SearchWrapper>
						)}
						{!isMobile && (
							<NavbarHeader.ChildrenWrapper>
								{
									newCategoryDesktop && newCategoryDesktop.length > 0 && (
										<CategoryDesktop
											data={newCategoryDesktop}
											destinationUrl={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`}
											prefixUrl="/category"
										/>
									)
								}
								<style>
									{`
										.top-menu {
											background: #FFF;
											box-shadow: inset 0px -1px 0px #DFE3E8;
											font-style: normal;
										}
										.top-menu a {
											font-family: 'Creato Display', sans-serif;
											color: #454F5B;
											letter-spacing: 0.42px;
										}
										.top-menu .title a {
											font-style: normal;
											font-weight: 400;
											font-family: 'Creato Display', sans-serif;
											font-size: 14px;
											font-style: normal;
											letter-spacing: 0.42px;
										}
										.profile-popup .profile-allo {
											display: none          
										}
					
										.profile-popup .option-menu i {
											margin: 0;
											padding: 0px 5px 0px 0px;
										}
										.profile-popup .profile-detail .profile-data, .profile-popup .profile-detail .profile-data .email, .profile-popup .profile-detail .profile-data .fullname, .button-profile-wrapper .name-wrapper p, .profile-popup .option-menu, .btn-login {
											font-family: 'Creato Display', sans-serif;
										}
										.loader-wrapper {
											display: flex;
											justify-content: center;
										}
									`}
								</style>
							</NavbarHeader.ChildrenWrapper>
						)}
					</NavbarHeader>
				)
			}
			{
				// do not show navbar if opened from mobile app webview
				isMobile && (device !== "1" && device !== "2") && (
					<>
						<NavbarSecondary
							show={isNavbarSecondary}
							isMobile
							title={titleNavbar}
							type={typeNavbarSecondary}
							isHideAction={isHideAction}
							onClickSidebar={() => setShowSidebar(true)}
							onClickSearch={() => HandleClickSearch()}
							onClickCart={() => HandleClickCart()}
							onClickBack={() => HandleClickBack()}
							cartBadgeCount={getCartBadgeCount || 0}
							is_show_search_suggest={isShowSearch}
							// searchContent={SearchContent}
							onChange={(e) => setSearchText(e.target.value)}
							search_text={searchText}
							onClick={() => setShowSearch(true)}
							close_search_suggest={() => setShowSearch(false)}
						/>
						<Sidebar
							show={isShowSidebar}
							user={self.data}
							isUserVerified={isVerified}
							sidebarCustom={newCategoryDesktop}
							onHide={() => setShowSidebar(false)}
						/>
					</>
				)
			}

			<div className="main-content fds-style">
				{children}
			</div>

			<style>
				{`
				.main-content {
					padding: ${mainContentPadding()}
				}
				@media screen and (max-width: 767px) {
					.main-content {
						max-width: 100vw;
					}
				}
					.top-menu {
					background: #FFF;
					box-shadow: inset 0px -1px 0px #DFE3E8;
					font-style: normal;
				}
				.top-menu a {
					font-family: 'Creato Display', sans-serif;
					color: #454F5B;
					letter-spacing: 0.42px;
				}
				.top-menu .title a {
					font-style: normal;
					font-weight: 400;
					font-family: 'Creato Display', sans-serif;
					font-size: 14px;
					font-style: normal;
					letter-spacing: 0.42px;
				}
				.profile-popup .profile-allo {
					display: none          
				}
				.profile-popup .option-menu i {
					margin: 0;
					padding: 0px 5px 0px 0px;
				}
				.profile-popup .profile-detail .profile-data, .profile-popup .profile-detail .profile-data .email, .profile-popup .profile-detail .profile-data .fullname, .button-profile-wrapper .name-wrapper p, .profile-popup .option-menu, .btn-login {
					font-family: 'Creato Display', sans-serif;
				}
				.search-input-field {
					font-size: 14px;
				}
				`}
			</style>

			{
				// do not show footer if opened from mobile app webview
				isFooter && (device !== "1" && device !== "2") && <Footer isMobile={isMobile} />
			}
			<Popup
				closable={popupShow?.closable}
				isMobile={isMobile}
				color={popupShow?.color}
				show={popupShow?.show}
				onHide={() => setPopupShow({
					show: false,
				})}
				text={popupShow?.message}
				withOk={false}
			/>

			<style>
				{`
						${!isMobile ? `.main-logo-wrapper a * {
							max-height: 45px;
							height: 45px;
							width: 135px;
						}
						
						button.search-button img.ic-object {
							height: 19px;
						}
						` : ""}

						img {
							max-width: 100%;
							max-height: 100%;
							width: auto;
							height: auto;
						}

						.navbar-inner-content-wrapper {
							max-width: 1200px;
						}

						.mega-menu-wrapper {
							max-width: 1200px;
							margin: 0 auto;
							display: flex;
							justify-content: center;
						}

						.search-input-field input {
							max-width: calc(100% - 32px);
						}

						.sub-menu-title a {
							text-transform: uppercase;
						}

						.menu-popup {
							max-width: calc(100% - 48px);
						}

						img.sub-menu-item {
							max-width: 100%;
						}

						${/* isHidetopMenu && */ !isMobile
			? ".mega-menu .menus-wrapper a {font-size: 14px; margin-top: 10px; margin-bottom: 10px;}"
			: ""
		}				.navbar-header {
							z-index: 5;
						}
						
						.navbar-header .btn-close {
							margin-left: 0;
						}

						.navbar-header .mega-menu {
							padding-bottom: 0;
						}

						.mega-menu-wrapper {
							width: 100%;
							max-width: 100%;
							margin-top: 12px;
							display: unset;
							justify-content: unset;
						}

						.navbar-header .search-popup-content {
							max-width: 100%;
						}

						.menu-popup {
							max-width: unset;
						}

						.menu-popup-title {
							justify-content: space-between;
						}

						.wrapper-search {
							position: relative;
						}
						.wrapper-search input {
							width: 100%;
							height: 36px;
							box-sizing: border-box;
							font-weight: 400;
							font-size: 14px;
							line-height: 16px;
							border: 1px solid #DFE3E8;
							border-radius: 2px;
							padding: 0 30px 0 10px;
							outline: none;
							margin: 5px 0 0 0;
						}
						.wrapper-search input:focus {
							border: 1px solid #1B8884;
						}
						.wrapper-search input::placeholder {
							color: #919EAB;
						}
						.wrapper-search .ic-search {
							position: absolute;
							right: 8px;
							top: 15px;
							cursor: pointer;
						}

						.button-profile-wrapper .profile-image, .profile-popup .profile-detail .profile-picture img {
							height: 67px;
							width: 67px;
						}

						${isMobile
			? `iframe#launcher {
									bottom: 70px !important;
									right: 12px !important;
								}`
			: ""
		}

					iframe[title="Close message"],
					iframe[title="Message from company"] {
						display: none !important;
						pointer-events: none !important;
					}
					`}
			</style>
			<style jsx global>
				{`
						.root-container {
							display: flex;
							align-items: center;
							justify-content: center;
							box-sizing: border-box;
						}
						.row-center {
							width: 480px;
							position: relative;
						}
	
						@media screen and (max-width: 425px) {
							.row-center {
								width: 100%;
							}
						}

						a {
							text-decoration: none;
						}

						body {
							margin: 0px;
							max-width: 100%;
							overflow-x: hidden;
						}
					`}
			</style>
		</>
	);

}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	titleNavbar: PropTypes.string,
	typeNavbarSecondary: PropTypes.string,
	ismobile: PropTypes.bool,
	isShowSecondaryNav: PropTypes.bool,
	self: PropTypes.objectOf(PropTypes.any).isRequired,
	categoryDesktop: PropTypes.any,
	isFooter: PropTypes.bool,
	getQuoteIdErrMessage: PropTypes.any,
	pageTitle: PropTypes.string,
	metaDescription: PropTypes.string,
	metaKeywords: PropTypes.string,
	metaTitle: PropTypes.string,
	metaImage: PropTypes.string,
	metaUrlPath: PropTypes.string,
	device: PropTypes.string,
	hideMetaDescription: PropTypes.bool,
	isHideAction: PropTypes.bool,
};

Layout.defaultProps = {
	ismobile: false,
	isFooter: true,
	titleNavbar: "",
	isShowSecondaryNav: true,
	getQuoteIdErrMessage: null,
	pageTitle: "",
	metaDescription: null,
	metaKeywords: null,
	metaTitle: null,
	metaImage: "",
	metaUrlPath: "",
	hideMetaDescription: false,
	isHideAction: false,
};
