import PropTypes from "prop-types";
import Mobile from "./mobile";
import Desktop from "./dekstop";

const SearchDefault = ({ isMobile, ...restProps }) => {

	if (isMobile) {

		return <Mobile {...restProps} />;

	}

	return <Desktop {...restProps} />;

};

SearchDefault.propTypes = {
	isMobile: PropTypes.bool,
};

SearchDefault.defaultProps = {
	isMobile: false,
};

export default SearchDefault;
