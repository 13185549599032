import Shimmer from "Components/shimmer";

const SearchSuggestionShimmer = () => (
	<div>
		<Shimmer borderRadius="24px" height="12px" width="60%" margin="0px 0px 12px 0px" />
		<Shimmer borderRadius="24px" height="12px" width="60%" margin="0px 0px 12px 0px" />
		<Shimmer borderRadius="24px" height="12px" width="40%" margin="0px 0px 12px 0px" />
	</div>
);

export default SearchSuggestionShimmer;
