import PropTypes from "prop-types";
import React from "react";
import { useRouter } from "next/router";
import { ArrayCheck, ArrayCheckWithLength } from "Helpers/checkArray";
import SearchProduct from "../search-product";
import SearchBrand from "../search-brand";
import SearchSuggestion from "../search-suggestion";

const SearchQuickDesktop = ({
	suggestions,
	brandTitle,
	brands,
	productTitle,
	products,
	searchText,
	onClickDataLayer,
	onClickDataLayerSubmit,
	submitSearchHistory,
}) => {

	const router = useRouter();

	const redirectToSearchViewAll = (section) => {

		// Analytics goes here later
		onClickDataLayerSubmit(section);
		submitSearchHistory(searchText);
		router.push(`/search?query=${encodeURIComponent(searchText)}&ref=quick`);

	};

	const setDataLayerClick = (data, section) => {

		onClickDataLayer(data, section, true);

	};

	return (
		<div className="search-quick">
			{/* Suggestions Section */}
			{
				ArrayCheck(suggestions) ? (
					<SearchSuggestion
						sugestions={suggestions}
						submitSearchHistory={submitSearchHistory}
						onClickDataLayer={setDataLayerClick}
					/>
				) : null
			}
			{/* Brands Section */}
			{
				ArrayCheck(brands) ? (
					<div>
						<div className="search-quick__heading">
							<div className="fd-body-lg-bold">
								{brandTitle || "Suggestion Brands"}
							</div>
							{
								ArrayCheckWithLength(products, 5) && (
									<button type="button" className="search-quick__view-all fd-body-md-bold" onClick={() => redirectToSearchViewAll("brand")}>
										View All
									</button>
								)
							}
						</div>
						<SearchBrand
							brands={brands}
							onClickDataLayer={setDataLayerClick}
						/>
					</div>
				) : null
			}
			{/* Popular Products Section */}
			{
				ArrayCheck(products) ? (
					<div>
						<div className="search-quick__heading">
							<div className="fd-body-lg-bold">
								{productTitle || "Suggestion Products"}
							</div>
							{
								ArrayCheckWithLength(products, 5) && (
									<button type="button" className="search-quick__view-all fd-body-md-bold" onClick={() => redirectToSearchViewAll("product")}>
										View All
									</button>
								)
							}
						</div>
						<SearchProduct
							products={products}
							onClickDataLayer={setDataLayerClick}
						/>
					</div>
				) : null
			}

			<style jsx>
				{`
					.search-quick {
						display: flex;
						flex-direction: column;
						gap: var(--space-20);
					}

					.search-quick__heading {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin: 0px 0px var(--space-12) 0px;
					}

					.search-quick__view-all {
						background-color: transparent;
						border: none;
						color: var(--bg-accent);
						cursor: pointer;
					}
				`}
			</style>
		</div>
	);

};

SearchQuickDesktop.propTypes = {
	suggestions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	),
	brandTitle: PropTypes.string,
	brands: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	),
	productTitle: PropTypes.string,
	products: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	),
	searchText: PropTypes.string,
	onClickDataLayer: PropTypes.func,
	onClickDataLayerSubmit: PropTypes.func,
	submitSearchHistory: PropTypes.func,
};

SearchQuickDesktop.defaultProps = {
	suggestions: [],
	brandTitle: "",
	brands: [],
	productTitle: "",
	products: [],
	searchText: "",
	onClickDataLayer: () => { },
	onClickDataLayerSubmit: () => { },
	submitSearchHistory: () => { },
};

export default SearchQuickDesktop;
