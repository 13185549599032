import PropTypes from "prop-types";
import ImageFallbackNext from "Components/image-with-fallback";
import { IdrConvert } from "Helpers/utils";
import { useRouter } from "next/router";

const SearchProductCard = ({
	imageUrl,
	productName,
	totalRating,
	totalReviews,
	brandName,
	price,
	regularPrice,
	slug,
	onClickDataLayer,
}) => {

	const router = useRouter();

	const redirectToProductDetail = () => {

		// Analytics goes here later
		onClickDataLayer(productName, "product");

		router.push(`/detail/${slug}`);

	};
	return (
		<div className="search-product-card" aria-hidden="true" onClick={redirectToProductDetail}>
			<div className="search-product-card__image">
				<ImageFallbackNext
					src={imageUrl}
					alt={productName ?? "img-product"}
					objectFit="contain"
				/>
			</div>
			<div className="search-product-card__content">
				{totalRating > 0 && totalReviews > 0 ? (
					<div className="search-product-card__rating">
						<span className="icon-ic_star fd-body-sm-regular" />
						<span className="fd-body-sm-regular">{totalRating}</span>
						<span className="search-product-card__total-reviews fd-body-sm-regular">{`(${totalReviews})`}</span>
					</div>
				) : null}
				<div className="search-product-card__brand-name fd-body-md-bold">
					{brandName}
				</div>
				<div className="search-product-card__product-name fd-body-md-regular">
					{productName}
				</div>
				<div className="search-product-card__price-wrapper">
					<span className="fd-body-md-bold">
						{IdrConvert(price ?? 0)}
					</span>
					{" "}
					{
						price < regularPrice && (
							<span className="search-product-card__regular-price fd-body-md-regular">
								{IdrConvert(regularPrice ?? 0)}
							</span>
						)
					}

				</div>
			</div>

			<style jsx>
				{`
							
						.search-product-card {
							display: grid;
							grid-template-columns: 80px 1fr;
							gap: var(--space-8);
							padding: 14px 0;
							border-bottom: 1px solid var(--border-default);
						}

						.search-product-card:first-child {
							padding-top: 0px;
						}
						.search-product-card:last-child {
							padding-bottom: 0px;
							border-bottom: none;
						}

						.search-product-card * {
							cursor: pointer;
						}
		
						.search-product-card__image {
							position: relative;
							min-width: 80px;
							min-height: 80px;
							aspect-ratio: 1 / 1;
						}

						.search-product-card__content {
							display: flex;
							justify-content: center;
							flex-direction: column;
							gap: 2px;
						}
		
						.search-product-card__total-reviews {
							color: var(--text-sub);
						}
	
						.search-product-card__rating {
							display: flex;
							align-items: center;
							gap: var(--space-4);
							height: var(--space-20);
						}
		
						.search-product-card__brand-name, 
						.search-product-card__product-name {
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
	
						.search-product-card__price-wrapper {
							display: flex;
							align-items: center;
							gap: var(--space-8);
						}
	
						.search-product-card__regular-price {
							text-decoration: line-through;
							color: var(--text-sub);
						}
		
					`}
			</style>
		</div>
	);

};

SearchProductCard.propTypes = {
	imageUrl: PropTypes.string,
	productName: PropTypes.string,
	totalRating: PropTypes.number,
	totalReviews: PropTypes.number,
	brandName: PropTypes.string,
	price: PropTypes.number,
	regularPrice: PropTypes.number,
	slug: PropTypes.string,
	onClickDataLayer: PropTypes.func,
};

SearchProductCard.defaultProps = {
	imageUrl: "",
	productName: "",
	totalRating: 0,
	totalReviews: 0,
	brandName: "",
	price: 0,
	regularPrice: 0,
	slug: "",
	onClickDataLayer: () => { },
};

export default SearchProductCard;
