import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";

const Pill = ({
	id, title, url, onClose, onClick,
}) => (
	<>
		<div id={id} onClick={() => onClick(title)} aria-hidden="true" className="pill">
			<Link href={url} passHref>
				<a href="replace" className="pill__text fd-body-md-regular">
					{title}
				</a>
			</Link>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={20}
				height={20}
				fill="none"
				onClick={onClose.bind(null, [title])}
				aria-hidden="true"
				style={{
					cursor: "pointer",
				}}
			>
				<path
					stroke="#13161A"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M15 5 5 15M5 5l10 10"
				/>
			</svg>
		</div>
		<style jsx>
			{`
				.pill {
					padding: var(--space-4) var(--space-12);
					border: 1px solid var(--border-default);
					border-radius: var(--space-24);
					cursor: pointer;

					display: flex;
					width: auto;
					gap: var(--space-4);
					max-height: var(--space-32);
				}

				.pill:hover {
					border: 1px solid var(--text-default);
				}

				.pill__text {
					color: var(--text-default);
					width: fit-content;
          max-width: 29ch;
					height: 22px;
					max-height: 22px;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
					display: block;
				}
				
			`}
		</style>
	</>
);

Pill.propTypes = {
	id: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
	title: PropTypes.string,
	url: PropTypes.string,
	onClose: PropTypes.func,
	onClick: PropTypes.func,
};

Pill.defaultProps = {
	title: "",
	url: "",
	onClose: () => {},
	onClick: () => {},
};

export default Pill;
