import PropTypes from "prop-types";
import Mobile from "./mobile";
import Desktop from "./desktop";

const SearchBrand = ({ isMobile, ...restProps }) => {

	if (isMobile) {

		return <Mobile {...restProps} />;

	}

	return <Desktop {...restProps} />;

};

SearchBrand.propTypes = {
	isMobile: PropTypes.bool,
};

SearchBrand.defaultProps = {
	isMobile: false,
};

export default SearchBrand;
