import Shimmer from "Components/shimmer";

const SearchProductShimmer = () => (
	<div>
		<Shimmer borderRadius="24px" height="12px" width="80px" margin="0px 0px 12px 0px" />
		{
			([...Array(5).keys()]).map((key) => (
				<div key={key} className="search-product-shimmer">
					<div>
						<Shimmer height="80px" width="80px" />
					</div>
					<div className="search-product-shimmer-text">
						<Shimmer borderRadius="24px" height="12px" width="88px" />
						<Shimmer borderRadius="24px" height="12px" width="100%" />
						<Shimmer borderRadius="24px" height="12px" width="100%" />
					</div>
				</div>

			))
		}

		<style jsx>
			{`
          
        .search-product-shimmer {
          display: grid;
          grid-template-columns: 80px 1fr;
          gap: var(--space-24);
        }

        .search-product-shimmer-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

      `}
		</style>
	</div>
);

export default SearchProductShimmer;
