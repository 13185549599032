import Image from "next/image";
import React from "react";
import PropTypes from "prop-types";
import Mobile from "./mobile";
import Desktop from "./desktop";

const ErrorView = () => (
	<div className="component-quick-search__section-image-error">
		<Image
			width={300}
			height={300}
			src={"https://image.femaledaily.com/images/page-error.svg"}
			alt="not-found"
			srcError={"https://image.femaledaily.com/dyn/200/images/broken-image/broken-image-rectangle.png"}
		/>
		<style jsx>
			{`
        .component-quick-search__section-image-error {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}
		</style>
	</div>
);

const SearchWrapper = ({ isMobile, ...restProps }) => {

	if (isMobile) {

		return (
			<Mobile {...restProps}>
				<ErrorView />
			</Mobile>
		);

	}

	return (
		<Desktop {...restProps}>
			<ErrorView />
		</Desktop>
	);

};

SearchWrapper.propTypes = {
	isMobile: PropTypes.bool,
};

SearchWrapper.defaultProps = {
	isMobile: false,
};

export default SearchWrapper;
