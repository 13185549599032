import React from "react";
import HistoryShimmer from "./history-shimmer";
import PromotionShimmer from "./promotion-shimmer";
import BrandShimmer from "./brand-shimmer";
import ProductShimmer from "./product-shimmer";
import TrendingShimmer from "./trending-shimmer";

const SearchShimmer = () => (
	<>
		<div className="search-shimmer-container">
			<HistoryShimmer />
			<PromotionShimmer />

			<BrandShimmer />
			<ProductShimmer />
			<TrendingShimmer />
		</div>
		<style jsx>
			{`
        .search-shimmer-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: var(--space-20);
        }
      `}
		</style>
	</>
);

export default SearchShimmer;
