import React from "react";
import BrandShimmer from "./brand-shimmer";
import ProductShimmer from "./product-shimmer";
import SuggestionShimmer from "./suggestion-shimmer";

const QuickSearchShimmer = () => (
	<>
		<div className="search-shimmer-container">
			<SuggestionShimmer />
			<BrandShimmer />
			<ProductShimmer />
		</div>
		<style jsx>
			{`
        .search-shimmer-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: var(--space-20);
        }
      `}
		</style>
	</>
);

export default QuickSearchShimmer;
