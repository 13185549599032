import PropTypes from "prop-types";
import React from "react";
// import SearchHistory from "@fdn/history_search";
import FdnPromotion from "@fdn/card_search_promotion";
import FdnCardSearchTrending from "@fdn/card_search_trending";
import { ArrayCheck } from "Helpers/checkArray";
import SearchProduct from "../search-product";
import SearchBrand from "../search-brand";
import SearchHistory from "../search-history";

const Mobile = ({
	histories,
	promotions,
	promotionTitle,
	promotionsLimit,
	trendings,
	trendingTitle,
	brands,
	brandTitle,
	products,
	productTitle,
	deleteHistorySearch,
	clearHistorySearch,
	submitSearch,
	onClickDataLayer,
}) => (
	<>
		<div className="search-default-container">
			{/* Search History Section */}
			{
				ArrayCheck(histories) ? (
					<>
						<SearchHistory
							id="studio"
							isMobile
							histories={histories}
							onClickRemove={deleteHistorySearch}
							onClickClear={clearHistorySearch}
							limit={5}
							onClickKeyword={(data) => onClickDataLayer(data, "history")}
							env={process.env.NEXT_PUBLIC_REVIEWS_DOMAIN}
						/>
					</>
				) : null
			}
			{/* Promotion Section */}
			{
				ArrayCheck(promotions) ? (
					<div className="search-default-container__search-promotion">
						<p className="search-default-container__title ed-heading-5">
							{promotionTitle || "Promotion"}
						</p>
						<div className="search-default-container__promotion-list">
							{promotions.map((promotion, index) => (
								(index < promotionsLimit) ? (
									<>
										<FdnPromotion
											key={promotion.name}
											isMobile
											promotion={promotion}
											on_click={() => onClickDataLayer(promotion.title, "promotion")}
										/>
									</>
								) : null
							))}
						</div>
					</div>
				) : null
			}
			{/* Trending Section */}
			{
				ArrayCheck(trendings) ? (
					<div className="search-default-container__search-trending">
						<p className="search-default-container__title ed-heading-5">
							{trendingTitle || "Trending Search"}
						</p>
						<div className="search-default-container__trending-list">
							{trendings.map((trending) => (
								<FdnCardSearchTrending
									key={trending}
									isMobile
									data={trending}
									submitSearch={submitSearch}
									on_click={() => onClickDataLayer(trending, "trending")}
								/>
							))}
						</div>
					</div>
				) : null
			}
			{/* Brands Section */}
			{
				ArrayCheck(brands) ? (
					<div className="search-default-container__search-trending">
						<p className="search-default-container__title ed-heading-5">
							{brandTitle || "Brands"}
						</p>
						<SearchBrand
							isMobile
							brands={brands}
							onClickDataLayer={onClickDataLayer}
						/>
					</div>

				) : null
			}
			{/* Popular Products Section */}
			{
				ArrayCheck(products) ? (
					<div className="search-default-container__search-trending">
						<p className="search-default-container__title ed-heading-5">
							{productTitle || "Popular Products"}
						</p>
						<SearchProduct
							isMobile
							products={products}
							onClickDataLayer={onClickDataLayer}
						/>
					</div>
				) : null
			}
		</div>
		<style jsx>
			{`
					.search-default-container {
						display: flex;
						flex-direction: column;
						gap: var(--space-20);
					}

					.search-default-container__title {
						margin: 0px 0px var(--space-12) 0px;
					}

					.search-default-container__promotion-list {
						display: flex;
						flex-direction: column;
						gap: var(--space-8);
					}

					.search-default-container__trending-list {
						max-width: 100%;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						gap: var(--space-8);
					}
				`}
		</style>
		<style jsx global>
			{`
					.card-promotion__promotion-title {
						margin: 0;
					}
					.card-promotion__promotion-label {
						margin: 0;
					}
					.card-trending-search__keyword {
						padding-top: var(--space-4) !important;
						padding-bottom: var(--space-4) !important;
					}
				`}
		</style>
	</>
);

Mobile.propTypes = {
	histories: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	),
	promotions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	),
	promotionTitle: PropTypes.string,
	promotionsLimit: PropTypes.string,
	trendingTitle: PropTypes.string,
	trendings: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	),
	brands: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	),
	brandTitle: PropTypes.string,
	products: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		}),
	),
	productTitle: PropTypes.string,
	deleteHistorySearch: PropTypes.func.isRequired,
	clearHistorySearch: PropTypes.func.isRequired,
	submitSearch: PropTypes.func,
	onClickDataLayer: PropTypes.func,
};

Mobile.defaultProps = {
	histories: [],
	promotions: [],
	promotionTitle: "Promotion",
	promotionsLimit: 3,
	trendingTitle: "Trending Search",
	trendings: [],
	brandTitle: "",
	brands: [],
	products: [],
	productTitle: "",
	submitSearch: () => { },
	onClickDataLayer: () => { },
};

export default Mobile;
