import Shimmer from "Components/shimmer";
import React from "react";

const TrendingShimmer = () => (
	<>
		<div>
			<Shimmer height="12px" width="80px" borderRadius="8px" margin="0px 0px 12px 0px" />
			<div className="shimmer-trending-list">
				{
					[...Array(3).keys()].map((idx) => (
						<Shimmer key={idx} borderRadius="24px" height="32px" width="80px" margin="0px" />
					))
				}
			</div>
		</div>
		<style jsx>
			{`
        .shimmer-trending-list {
            display: flex;
            flex-direction: row;
            gap: var(--radius-8);
          }
      `}
		</style>
	</>
);

export default TrendingShimmer;
