import PropTypes from "prop-types";

import SearchProductCard from "./product-card";

const SearchProduct = ({
	products,
	onClickDataLayer,
}) => (
	<div>
		<div className="search-product-list">
			{
				(products ?? []).slice(0, 5).map((product) => (
					<SearchProductCard
						key={product.id}
						imageUrl={product.assets?.[0]?.url}
						productName={product.product.name}
						brandName={product.brand.name}
						price={product.product.price}
						regularPrice={product.product.regular_price}
						totalRating={product.rating.rating_overall}
						totalReviews={product.total_review}
						slug={product.slug}
						onClickDataLayer={onClickDataLayer}
					/>

				))
			}

		</div>
		<style jsx>
			{`
          
        .search-product-list {
          display: flex;
          flex-direction: column;
					margin-bottom: var(--space-16);
        }

      `}
		</style>
	</div>
);

SearchProduct.propTypes = {
	products: PropTypes.arrayOf(PropTypes.objectOf({
		imageUrl: PropTypes.string,
		productName: PropTypes.string,
		totalRating: PropTypes.number,
		totalReviews: PropTypes.number,
		brandName: PropTypes.string,
		price: PropTypes.number,
		discount: PropTypes.number,
	})),
	onClickDataLayer: PropTypes.func,
};

SearchProduct.defaultProps = {
	products: [],
	onClickDataLayer: () => { },
};

export default SearchProduct;
