import Shimmer from "Components/shimmer";
import React from "react";

const PromotionShimmer = () => (
	<>
		<div>
			<Shimmer height="12px" width="80px" borderRadius="8px" margin="0px 0px 12px 0px" />
			<div className="shimmer-promotion-list">
				{
					[...Array(3).keys()].map((idx) => (
						<div className="shimmer-promotion-card">
							<Shimmer key={idx} borderRadius="0px" height="40px" width="40px" margin="0px" />
							<Shimmer key={idx} borderRadius="8px" height="12px" width="80%" margin="0px" />
						</div>
					))
				}
			</div>
		</div>
		<style jsx>
			{`
        .shimmer-promotion-list {
          display: flex;
          flex-direction: column;
          gap: var(--radius-8);
        }
        .shimmer-promotion-card {
          display: flex;
          align-items: center;
          gap: var(--space-12);
          max-width: 328px;
        }
      `}
		</style>
	</>
);

export default PromotionShimmer;
