import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
	toBase64, shimmerImgLoader,
} from "Helpers/utils";

const ImageWithFallback = ({
	src, alt, outOfStock, objectFit,
}) => {

	const fallBackSrc = "/images/placeholder_image_not_found.png";
	const [state, setState] = useState({
		imgUrl: null,
		isLoaded: false,
	});
	const changeImage = () => {

		setState((prevState) => ({ ...prevState, imgUrl: fallBackSrc, isLoaded: true }));

	};
	useEffect(() => {

		setState((prevState) => ({ ...prevState, imgUrl: src, isLoaded: true }));

	}, [src]);

	return (
		<>
			<img
				className={`img-fallback ${outOfStock && "OOS"}`}
				src={!state.isLoaded ? `data:image/svg+xml;base64,${toBase64(shimmerImgLoader(400, 400))}` : state.imgUrl}
				alt={alt}
				onLoad={() => setState((prevState) => ({ ...prevState, isLoaded: true }))}
				onError={() => changeImage()}
				style={{
					objectFit: objectFit || "cover",
				}}
			/>
			<style jsx>
				{`
			    .img-fallback{
					position: absolute;
					inset: 0px;
					box-sizing: border-box;
					padding: 0px;
					border: none;
					margin: auto;
					display: block;
					min-width: 100%;
					max-width: 100%;
					min-height: 100%;
					max-height: 100%;
				}

			    .img-fallback.OOS{
					opacity: 0.5;
				}
			`}
			</style>
		</>
	);

};

ImageWithFallback.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string,
	outOfStock: PropTypes.bool,
	objectFit: PropTypes.string,
};

ImageWithFallback.defaultProps = {
	outOfStock: false,
	alt: "image",
	objectFit: "cover",
};

export default ImageWithFallback;
