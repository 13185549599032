import Shimmer from "Components/shimmer";

const SearchBrandShimmer = () => (
	<div>
		<Shimmer borderRadius="24px" height="12px" width="80px" margin="0px 0px 12px 0px" />
		<div className="search-brand-shimmer">
			{
				([...Array(4).keys()]).map((key) => (
					<Shimmer key={key} height="80px" width="100%" />
				))
			}
		</div>

		<style jsx>
			{`
				@media screen and (max-width: 480px) {
					.search-brand-shimmer {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: var(--space-16);
					}
				}

				@media screen and (min-width: 481px) {
					.search-brand-shimmer {
						display: flex;
						align-items: center;
						gap: var(--space-12);
						overflow-x: auto;
						overflow-y: hidden;
						scrollbar-width: none;
					}
				}

      `}
		</style>
	</div>
);

export default SearchBrandShimmer;
