import PropTypes from "prop-types";

import BrandCard from "@fdn/card_search_brand";
import { useRouter } from "next/router";

const SearchBrandMobile = ({ brands, onClickDataLayer }) => {

	const router = useRouter();

	const onClickCard = (name, slug) => {

		// Analytics goes here later
		onClickDataLayer(name, "brand");
		router.push(`/brands/${slug}`);

	};

	return (
		<div>
			<div className="search-brand-mobile">
				{brands.slice(0, 4).map((brand) => (
					<BrandCard
						key={brand.id}
						isMobile
						brand={brand}
						brokenImage={`${process.env.NEXT_PUBLIC_S3_URL}/placeholder_image_not_found.png`}
						width="auto"
						height="auto"
						onClick={() => onClickCard(brand.name, brand.slug)}
					/>
				))}
			</div>

			<style jsx global>
				{`

				.search-brand-mobile .txt-brand {
					margin-top: var(--space-8);
					margin-bottom: 0;
				}
          
        @media screen and (max-width: 480px) {
					.search-brand-mobile {
						display: grid;
						grid-template-columns: 1fr 1fr;
						gap: var(--space-8) var(--space-16);
					}

					.search-brand-mobile .container-search-default__section-image-brand {
						aspect-ratio: 2/1;
					}
				}

				@media screen and (min-width: 481px) {
					.search-brand-mobile {
						display: flex;
						align-items: center;
						gap: var(--space-12);
						overflow-x: auto;
						overflow-y: hidden;
						scrollbar-width: none;
					}

					.search-brand-mobile .container-search-default__section-image-brand {
						width: 120px;
						height: 62px;
					}
				}

      `}
			</style>
		</div>
	);

};

SearchBrandMobile.propTypes = {
	brands: PropTypes.arrayOf(PropTypes.objectOf({
		logo: PropTypes.string,
		name: PropTypes.string,
		slug: PropTypes.number,
		id: PropTypes.number,
		url: PropTypes.string,
	})),
	onClickDataLayer: PropTypes.func,
};

SearchBrandMobile.defaultProps = {
	brands: [],
	onClickDataLayer: () => { },
};

export default SearchBrandMobile;
