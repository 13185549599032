import React from "react";
import PropTypes from "prop-types";

const Shimmer = ({
	height,
	width,
	borderRadius,
	id,
	margin,
}) => (
	<div className={`fdn-shimmer-${id}`}>
		<style jsx>
			{`
          .fdn-shimmer-${id} {
            animation-name: wave;
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            background-color: #f2f2f2;
            background-image: linear-gradient(
              125deg,
              #d8d8d8 20%,
              #f2f2f2 100%,
              #d8d8d8 50%
            );
            height: ${height};
            width: ${width};
            background-size: 50rem 10rem;
            margin: ${margin};
            border-radius: ${borderRadius};
            box-sizing: border-box;
          }
          @keyframes wave {
            0% {
              background-position: -25rem 0;
            }
            100% {
              background-position: 25rem 0;
            }
          }
        `}
		</style>
	</div>
);

Shimmer.propTypes = {
	height: PropTypes.string,
	width: PropTypes.string,
	borderRadius: PropTypes.string,
	id: PropTypes.string,
	margin: PropTypes.string,
};

Shimmer.defaultProps = {
	height: "10px",
	width: "20%",
	borderRadius: "4px",
	id: "shimmerId",
	margin: "0 0 10px 0",
};

export default Shimmer;
