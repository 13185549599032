import { IconSearch } from "@tabler/icons";
import PropTypes from "prop-types";

const SearchSuggestion = ({ sugestions, onClickDataLayer, submitSearchHistory }) => {

	const clickAction = (e, suggestion) => {

		e.preventDefault();
		submitSearchHistory(suggestion);
		window.location.href = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/search?query=${encodeURIComponent(suggestion)}&ref=quick`;
		onClickDataLayer(suggestion, "keyword_suggestion");

	};

	return (
		<div className="search-suggestion">
			<div className="search-suggestion__list">
				{sugestions.slice(0, 3).map((suggestion) => (
					<div
						className="search-suggestion__item"
						onClick={(e) => clickAction(e, suggestion)}
						aria-hidden="true"
					>
						<IconSearch width={16} height={16} />
						<div className="fd-body-md-regular search-suggestion__text">{suggestion}</div>
					</div>
				))}
			</div>

			<style jsx>
				{`

        .search-suggestion__list {
          display: flex;
          flex-direction: column;
          gap: var(--space-12)
        }

        .search-suggestion__text {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .search-suggestion__item {
          display: flex;
					align-items: center;
          gap: var(--space-12);
					cursor: pointer;
        }

      `}
			</style>
		</div>
	);

};

SearchSuggestion.propTypes = {
	sugestions: PropTypes.arrayOf(PropTypes.string),
	onClickDataLayer: PropTypes.func,
	submitSearchHistory: PropTypes.func,
};

SearchSuggestion.defaultProps = {
	sugestions: [],
	onClickDataLayer: () => { },
	submitSearchHistory: () => { },
};

export default SearchSuggestion;
