// * if this component has finish update eslint or delete eslint below
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import SearchDefault from "Components/search/search-default";
// import Image from "next/image";
import React from "react";
import PropTypes from "prop-types";
import SearchShimmer from "Components/search/search-shimmer";
import SearchQuick from "Components/search/search-quick";
import QuickSearchShimmer from "Components/search/search-shimmer/quick-search-shimmer";
import Image from "next/image";

const Mobile = (props) => {

	const renderSection = () => {

		if (props.isLoading && props.isDefault) {

			return <SearchShimmer />;

		}

		if (props.isLoading && !props.isDefault) {

			return <QuickSearchShimmer />;

		}

		if (props.isDefault) {

			if (props.searchDefault?.code !== 200) {

				return (
					<div className="component-quick-search__section-image-error">
						<Image
							width={200}
							height={200}
							src={"https://image.femaledaily.com/error-image/image-broken-mirror-1.png"}
							alt="not-found"
							srcError={"https://image.femaledaily.com/dyn/200/images/broken-image/broken-image-rectangle.png"}
						/>
						<div className="component-quick-search__section-text">
							<p className="text fd-body-lg-bold">Uh Oh!</p>
							<p className="text fd-body-lg-regular">Something went wrong</p>
						</div>
						<style jsx>
							{`
								.component-quick-search__section-image-error {
									width: 100%;
									display: flex;
									flex-direction: column;
									align-items: center;
									justify-content: center;
									gap: var(--space-24);
									margin: var(--space-32) 0;
								}
								.component-quick-search__section-text {
									display: flex;
									flex-direction: column;
									align-items: center;
									gap: var(--space-8);
								}

								.component-quick-search__section-text .text {
									margin: 0;
								}

							`}
						</style>
					</div>
				);

			}

			if (props.searchDefault.data?.promotion?.section.length < 1
				&& props.searchDefault.data?.trendings?.section.length < 1
				&& props.searchDefault.data?.popular?.section.length < 1
			) {

				return props.children;

			}

			return (
				<SearchDefault
					isMobile
					// histories
					histories={props.searchDefault.data?.history || []}
					historyOnClickRemove={props.historyOnClickRemove}
					deleteHistorySearch={props.deleteHistorySearch}
					clearHistorySearch={props.clearHistorySearch}
					historiesLimit={5}
					// promotions
					promotions={props.searchDefault.data?.promotion?.section || []}
					promotionTitle={props.searchDefault.data?.promotion?.name || "-"}
					promotionsLimit={3}
					// trending
					trendings={props.searchDefault.data?.trendings?.section || []}
					trendingTitle={props.searchDefault.data?.trendings?.name || "-"}
					submitSearch={props.submitSearch}
					// brands
					brands={
						(props.searchDefault.data?.popular?.section || []).filter(
							(item) => item.type === "brand",
						)?.[0]?.data || []
					}
					brandsLimit={5}
					// products
					products={
						(props.searchDefault.data?.popular?.section || []).filter(
							(item) => item.type === "product",
						)?.[0]?.data || []
					}
					productsLimit={4}
					onClickDataLayer={props.onClickDataLayer}
				/>
			);

		}

		if (props.handleNoResult) {

			return props.children;

		}

		return (
			<SearchQuick
				isMobile
				// brands
				brands={
					props.searchQuick.data?.results?.brands || []
				}
				// product
				products={
					props.searchQuick.data?.results?.products || []
				}
				// suggestions
				suggestions={props.searchQuick.data?.suggestions || []}
				searchText={props.searchText}
				onClickDataLayer={props.onClickDataLayer}
				onClickDataLayerSubmit={props.onClickDataLayerSubmit}
				submitSearchHistory={props.submitSearchHistory}
			/>
		);

	};

	return (
		<>
			<div className="search-result-container">
				{renderSection()}
			</div>
			<style jsx>
				{`
        .search-result-container {
          width: auto;
					max-width: 100%;
          min-height: calc(100vh - 62px);
          padding: 9px var(--space-16) var(--space-16) var(--space-16);
					margin-bottom: var(--space-80);
					z-index: 3;
        }
      `}
			</style>
		</>
	);

};

Mobile.propTypes = {
	isLoading: PropTypes.bool,
};

Mobile.defaultProps = {
	isLoading: false,
};

export default Mobile;
