import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ArrayCheck } from "Helpers/checkArray";
import Pill from "Components/pill";

const Mobile = ({
	histories, onClickRemove, onClickClear, onClickKeyword,
}) => {

	const [historiesData, setHistoriesData] = useState([]);

	const onClickRemoveData = (dataRemove) => {

		onClickRemove(dataRemove);

		const arr = historiesData.filter((data) => data !== dataRemove[0]);
		setHistoriesData(arr);

	};

	const onRemoveAllData = () => {

		setHistoriesData([]);
		onClickClear();

	};

	useEffect(() => {

		setHistoriesData(histories);

	}, []);

	if (historiesData.length < 1) {

		return null;

	}

	return (
		<>
			<div className="search-history-container">
				<div className="search-history-container__section-title">
					<p className="search-history-container__title ed-heading-5">
						Search History
					</p>
					<button
						className="search-history-container__button fd-body-md-bold"
						onClick={onRemoveAllData}
						type="button"
					>
						Clear All
					</button>
				</div>
				<div className="search-history-container__list">
					{ArrayCheck(histories) ? (
						historiesData.map((history) => (
							<Pill
								title={history}
								url={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/search?query=${encodeURIComponent(history)}&ref=default`}
								onClose={onClickRemoveData}
								onClick={onClickKeyword}
							/>
						))
					) : null}
				</div>
			</div>
			<style jsx>
				{`
					.search-history-container__section-title {
						display: flex;
						justify-content: space-between;
						margin-bottom: var(--space-12);
					}

					.search-history-container__title {
						margin: 0;
					}

					.search-history-container__button {
						background-color: transparent;
						border: none;
						cursor: pointer;

						color: var(--bg-accent);
					}

					.search-history-container__list {
						display: flex;
						flex-wrap: wrap;
						gap: var(--space-8);
					}

				`}
			</style>
		</>
	);

};

Mobile.propTypes = {
	histories: PropTypes.arrayOf(
		PropTypes.string,
	),
	onClickRemove: PropTypes.func,
	onClickClear: PropTypes.func,
	onClickKeyword: PropTypes.func,
};

Mobile.defaultProps = {
	histories: [],
	onClickRemove: () => { },
	onClickClear: () => { },
	onClickKeyword: () => { },
};

export default Mobile;
