import PropTypes from "prop-types";

import BrandCard from "@fdn/card_search_brand";
import { useRouter } from "next/router";

const SearchBrandDesktop = ({ brands, onClickDataLayer }) => {

	const router = useRouter();

	const onClickCard = (name, slug) => {

		// Analytics goes here later
		onClickDataLayer(name, "brand");
		router.push(`/brands/${slug}`);

	};

	return (
		<>
			<div className="search-brand-desktop">
				{brands.slice(0, 5).map((brand) => (
					<BrandCard
						key={brand.id}
						isMobile
						brand={brand}
						brokenImage={`${process.env.NEXT_PUBLIC_S3_URL}/placeholder_image_not_found.png`}
						width="120px"
						height="62px"
						onClick={() => onClickCard(brand.name, brand.slug)}
					/>
				))}
			</div>

			<style jsx global>
				{`

				.search-brand-desktop {
          display: flex;
					align-items: center;
          gap: var(--space-12);
					overflow-x: auto;
					overflow-y: hidden;
					scrollbar-width: none;
        }

				.search-brand-desktop .txt-brand-container > p.txt-brand {
					margin-top: var(--space-8);
					margin-bottom: 0;
				}

        .search-brand-desktop::-webkit-scrollbar {
          display: none;
        }

      `}
			</style>
		</>
	);

};

SearchBrandDesktop.propTypes = {
	brands: PropTypes.arrayOf(PropTypes.objectOf({
		logo: PropTypes.string,
		name: PropTypes.string,
		slug: PropTypes.number,
		id: PropTypes.number,
		url: PropTypes.string,
	})),
	onClickDataLayer: PropTypes.func,
};

SearchBrandDesktop.defaultProps = {
	brands: [],
	onClickDataLayer: () => { },
};

export default SearchBrandDesktop;
